import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import benefitIcon1 from '../../../assets/image/svg/trending-up.svg';
import benefitIcon2 from '../../../assets/image/svg/heartbeat.svg';
import benefitIcon3 from '../../../assets/image/svg/bulb.svg';
import benefitIcon4 from '../../../assets/image/svg/medal-2.svg';
import benefitIcon5 from '../../../assets/image/svg/soup.svg';
import benefitIcon6 from '../../../assets/image/svg/code.svg';
import '../../../styles/benefits.css';
import BenefitTitle from '../components/BenefitTitle';
import BenefitList from '../components/BenefitList';
import Benefit from '../components/Benefit';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <section className='grid grid-cols-12 pt-10 md:pb-7 gap-y-6 gap-x-6'>
      <div className='col-span-12'>
        <AnimationWrapper from='left'>
          <h2 className='text-2xl md:text-3xl font-bold w-full text-center md:text-left md:max-w-[80%]'>
            {t('lifeAtSmarttie.benefits.title')}
            <span className='text-primary'>
              {t('lifeAtSmarttie.benefits.subtitle1')}
            </span>
            {t('lifeAtSmarttie.benefits.subtitle2')}
          </h2>
        </AnimationWrapper>
      </div>
      <Benefit bg='benefit-bg-1'>
        <div className='relative z-[1] flex flex-col'>
          <img
            src={benefitIcon1}
            about='Profesional develoment icon'
            className='w-[80px] h-[80px] mx-auto'
            alt='Trending up icon'
          />
          <BenefitTitle title={t('lifeAtSmarttie.benefits.benefit1.title')} />
          <BenefitList>
            <li>{t('lifeAtSmarttie.benefits.benefit1.point1')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit1.point2')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit1.point3')}</li>
          </BenefitList>
        </div>
      </Benefit>

      <Benefit bg='benefit-bg-2' delay={0.3}>
        <div className='relative z-[1]'>
          <img
            src={benefitIcon2}
            about='Profesional develoment icon'
            className='w-[80px] h-[80px] mx-auto'
            alt='Heartbeat icon'
          />
          <BenefitTitle title={t('lifeAtSmarttie.benefits.benefit2.title')} />
          <BenefitList>
            <li>{t('lifeAtSmarttie.benefits.benefit2.point1')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit2.point2')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit2.point3')}</li>
          </BenefitList>
        </div>
      </Benefit>

      <Benefit bg='benefit-bg-3'>
        <div className='relative z-[1]'>
          <img
            src={benefitIcon3}
            about='Profesional develoment icon'
            className='w-[80px] h-[80px] mx-auto'
            alt='Bulb icon'
          />
          <BenefitTitle title={t('lifeAtSmarttie.benefits.benefit3.title')} />
          <BenefitList>
            <li>{t('lifeAtSmarttie.benefits.benefit3.point1')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit3.point2')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit3.point3')}</li>
          </BenefitList>
        </div>
      </Benefit>

      <Benefit bg='benefit-bg-4' delay={0.3}>
        <div className='relative z-[1]'>
          <img
            src={benefitIcon4}
            about='Profesional develoment icon'
            className='w-[80px] h-[80px] mx-auto'
            alt='Medal icon'
          />
          <BenefitTitle title={t('lifeAtSmarttie.benefits.benefit4.title')} />
          <BenefitList>
            <li>{t('lifeAtSmarttie.benefits.benefit4.point1')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit4.point2')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit4.point3')}</li>
          </BenefitList>
        </div>
      </Benefit>

      <Benefit bg='benefit-bg-5'>
        <div className='relative z-[1]'>
          <img
            src={benefitIcon5}
            about='Profesional develoment icon'
            className='w-[80px] h-[80px] mx-auto'
            alt='Soup icon'
          />
          <BenefitTitle title={t('lifeAtSmarttie.benefits.benefit5.title')} />
          <BenefitList>
            <li>{t('lifeAtSmarttie.benefits.benefit5.point1')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit5.point2')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit5.point3')}</li>
          </BenefitList>
        </div>
      </Benefit>

      <Benefit bg='benefit-bg-6' delay={0.3}>
        <div className='relative z-[1]'>
          <img
            src={benefitIcon6}
            about='Profesional develoment icon'
            className='w-[80px] h-[80px] mx-auto'
            alt='Code icon'
          />
          <BenefitTitle title={t('lifeAtSmarttie.benefits.benefit6.title')} />
          <BenefitList>
            <li>{t('lifeAtSmarttie.benefits.benefit6.point1')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit6.point2')}</li>
            <li>{t('lifeAtSmarttie.benefits.benefit6.point3')}</li>
          </BenefitList>
        </div>
      </Benefit>
    </section>
  );
};

export default Benefits;
