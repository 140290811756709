import React from 'react';
import InfoImage from '../components/InfoImage';
import img1 from '../../../assets/image/value-img-1.jpg';
import img2 from '../../../assets/image/value-img-2.jpg';
import img3 from '../../../assets/image/value-img-3.jpg';
import img4 from '../../../assets/image/value-img-4.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';

const OurValues = () => {
  const { t } = useTranslation();

  return (
    <section className='grid grid-cols-12 pt-10 md:pb-20'>
      <div className='col-span-12 pt-6'>
        <AnimationWrapper from='right'>
          <h2 className='text-3xl md:text-4xl font-bold w-full text-center md:text-right'>
            {t('lifeAtSmarttie.ourValues.title')}
            <span className='text-primary'>
              {' '}
              {t('lifeAtSmarttie.ourValues.subtitle')}
            </span>
          </h2>
        </AnimationWrapper>
      </div>
      <InfoImage
        title={t('lifeAtSmarttie.ourValues.value1.title')}
        description={t('lifeAtSmarttie.ourValues.value1.description')}
        img={img1}
        reverse={true}
      />
      <InfoImage
        title={t('lifeAtSmarttie.ourValues.value2.title')}
        description={t('lifeAtSmarttie.ourValues.value2.description')}
        img={img2}
      />
      <InfoImage
        title={t('lifeAtSmarttie.ourValues.value3.title')}
        description={t('lifeAtSmarttie.ourValues.value3.description')}
        img={img3}
        reverse={true}
      />
      <InfoImage
        title={t('lifeAtSmarttie.ourValues.value4.title')}
        description={t('lifeAtSmarttie.ourValues.value4.description')}
        img={img4}
      />
    </section>
  );
};

export default OurValues;
