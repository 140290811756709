import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';

const JobsCTA = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[url('../assets/image/life-cta-bg.jpg')] bg-center bg-cover">
      <AnimationWrapper from='left'>
        <div className='container mx-auto h-[50vh] max-h-[350px] grid items-center'>
          <div className='w-full lg:w-[70%] xl:w-[40%] flex flex-col gap-3'>
            <h2 className='font-bold text-5xl pb-2 text-center lg:text-left'>
              {t('lifeAtSmarttie.jobsCTA.title')}
            </h2>

            <p className='text-base lg:text-lg text-center lg:text-left'>
              {t('lifeAtSmarttie.jobsCTA.description')}
            </p>
            <a
              href='https://www.linkedin.com/company/smarttie/jobs/'
              target='_blank'
              rel='noreferrer'
              className='bg-animation-container font-semibold hover:text-white border py-2 px-4 border-primary rounded-full text-center relative overflow-hidden text-white cta-hover-animation'
            >
              <div className='w-full h-full grid place-items-center absolute top-0 left-0'>
                <div className='bg-animation-center rounded-full'></div>
              </div>
              <span className='relative z-[2]'>
                {t('lifeAtSmarttie.jobsCTA.button')}
              </span>
            </a>
          </div>
        </div>
      </AnimationWrapper>
    </div>
  );
};

export default JobsCTA;
