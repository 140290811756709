import React from 'react';

const About = ({ children, title, subtitle }) => {
  return (
    <div className='w-full flex flex-col gap-1 pt-2 md:pt-6 lg:pt-12 pb-10 lg:pb-28'>
      <h2 className='text-center md:text-left text-2xl sm:text-3xl font-bold'>
        {title}
      </h2>
      <h3 className='text-center md:text-left text-primary text-lg sm:text-xl font-bold'>
        {subtitle}
      </h3>
      {children}
    </div>
  );
};

export default About;
