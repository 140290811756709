import React from 'react';
import InfoImage from '../components/InfoImage';
import img1 from '../../../assets/image/life-img-1.jpg';
import img2 from '../../../assets/image/life-img-2.jpg';
import img3 from '../../../assets/image/life-img-3.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';

const SmarttieLife = () => {
  const { t } = useTranslation();

  return (
    <section className='grid grid-cols-12 pt-10 md:pb-20'>
      <div className='col-span-12 pt-6'>
        <AnimationWrapper from='left'>
          <h2 className='text-3xl md:text-4xl font-bold w-full text-center md:text-left'>
            {t('lifeAtSmarttie.life.title')}
            <span className='text-primary'> Smarttie</span>
          </h2>
          <p className='text-center md:text-left pt-4 px-0 sm:px-5 md:px-0'>
            {t('lifeAtSmarttie.life.description')}
            <span className='font-bold'>
              {t('lifeAtSmarttie.life.descriptionCTA')}
            </span>
          </p>
        </AnimationWrapper>
      </div>

      <h3 className='col-span-12 pt-14 md:pt-3 text-2xl md:text-3xl font-bold w-full text-center md:text-right'>
        <AnimationWrapper from='right'>
          <span className='text-primary'>
            {t('lifeAtSmarttie.life.topic1.title')}
          </span>
          {t('lifeAtSmarttie.life.topic1.subtitle')}
        </AnimationWrapper>
      </h3>
      <InfoImage
        description={t('lifeAtSmarttie.life.topic1.description')}
        img={img1}
      />

      <h3 className='col-span-12 pt-14 md:pt-3 text-2xl md:text-3xl font-bold w-full text-center md:text-left'>
        <AnimationWrapper from='left'>
          {t('lifeAtSmarttie.life.topic2.title')}
          <span className='text-primary'>
            {t('lifeAtSmarttie.life.topic2.subtitle')}
          </span>
        </AnimationWrapper>
      </h3>
      <InfoImage
        description={t('lifeAtSmarttie.life.topic2.description')}
        img={img2}
        reverse={true}
      />
      <h3 className='col-span-12 pt-14 md:pt-3 text-2xl md:text-3xl font-bold w-full text-center md:text-right'>
        <AnimationWrapper from='right'>
          <span className='text-primary'>
            {t('lifeAtSmarttie.life.topic3.title')}
          </span>
          {t('lifeAtSmarttie.life.topic3.subtitle')}
        </AnimationWrapper>
      </h3>
      <InfoImage
        description={t('lifeAtSmarttie.life.topic3.description')}
        img={img3}
      />
    </section>
  );
};

export default SmarttieLife;
