import React from 'react';

const BenefitTitle = ({ title }) => {
  return (
    <h3 className='text-center text-2xl md:text-xl xl:text-2xl w-[85%] sm:w-[90%] xl:w-[85%] font-bold mx-auto min-h-[70px] flex items-center justify-center'>
      {title}
    </h3>
  );
};

export default BenefitTitle;
