import React from 'react';
import aboutImg1Jpg from '../../../assets/image/life-about-img-1.jpg';
import aboutImg2Jpg from '../../../assets/image/life-about-img-2.jpg';
import About from '../components/About';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import LazyLoad from 'react-lazyload';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <AnimationWrapper from='left' time={0.3} delay={0}>
        <About
          title={t('lifeAtSmarttie.about1.title')}
          subtitle={t('lifeAtSmarttie.about1.subtitle')}
        >
          <p className='mb-7 text-center md:text-justify'>
            {t('lifeAtSmarttie.about1.p1')}
          </p>
          <p className='text-center md:text-justify'>
            {t('lifeAtSmarttie.about1.p2')}
          </p>
          <LazyLoad once height={200}>
            <picture>
              <img
                src={aboutImg1Jpg}
                alt='About image 1'
                className='rounded-md mt-3 w-full min-h-[240px] object-cover sm:min-h-0'
              />
            </picture>
          </LazyLoad>
        </About>
      </AnimationWrapper>
      <AnimationWrapper from='bottom'>
        <About
          title={t('lifeAtSmarttie.about2.title')}
          subtitle={t('lifeAtSmarttie.about2.subtitle')}
        >
          <p className='mb-7 text-center md:text-justify'>
            {t('lifeAtSmarttie.about2.p1')}
          </p>
          <p className='text-center md:text-justify'>
            {t('lifeAtSmarttie.about2.p2')}
          </p>
          <LazyLoad once height={200}>
            <picture>
              <img
                src={aboutImg2Jpg}
                alt='About image 1'
                className='rounded-md mt-3 w-full min-h-[240px] object-cover sm:min-h-0'
              />
            </picture>
          </LazyLoad>
        </About>
      </AnimationWrapper>
    </>
  );
};

export default AboutUs;
