import React from 'react';
import closeIcon from '../../../assets/image/svg/line.svg';
import openIcon from '../../../assets/image/svg/cross.svg';
import '../../../styles/accordion.css';

const FAQItem = ({ title, content, isOpen, controlledChange }) => {
  // ** State

  return (
    <div className='FAQ-item relative h-full'>
      <div
        className={`w-full grid place-content-center absolute -top-10 FAQ-box ${isOpen &&
          'FAQ-box-shadow'}`}
      ></div>
      <div className='w-full cursor-default p-4'>
        <div
          className='flex items-center justify-between cursor-pointer'
          onClick={() => {
            controlledChange();
          }}
        >
          <h3 className='text-base md:text-lg lg:text-base xl:text-lg  font-bold py-2'>
            {title}
          </h3>
          <span className='text-5xl font-extrabold flex items-center'>
            {isOpen ? (
              <img src={openIcon} alt='open icon' className='w-4 h-4' />
            ) : (
              <img src={closeIcon} alt='open icon' className='w-4 h-4' />
            )}
          </span>
        </div>
        <hr />
        <div
          className={`FAQ-item-content ${
            isOpen ? 'FAQ-item-content-opened' : 'FAQ-item-content-closed'
          }`}
        >
          <p className='text-white text-sm py-4'>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default FAQItem;
