import React, { useState } from 'react';
import FAQItem from '../components/FAQItem';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';

const FAQ = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState('');

  const handleExpand = (faq) => {
    if (expanded === faq) {
      setExpanded('');
    } else {
      setExpanded(faq);
    }
  };

  return (
    <section className='col-span-12 pt-8 pb-16'>
      <AnimationWrapper from='bottom'>
        <h2 className='text-xl text-center md:text-left md:text-2xl font-bold pb-3'>
          {t('lifeAtSmarttie.FAQS.title')}
        </h2>
      </AnimationWrapper>

      <div className='pt-3 flex flex-col gap-4'>
        <AnimationWrapper from='bottom'>
          <FAQItem
            isOpen={expanded === 'faq1'}
            controlledChange={() => handleExpand('faq1')}
            title={t('lifeAtSmarttie.FAQS.FAQ1.title')}
            content={t('lifeAtSmarttie.FAQS.FAQ1.description')}
          />
        </AnimationWrapper>

        <AnimationWrapper from='bottom'>
          <FAQItem
            isOpen={expanded === 'faq2'}
            controlledChange={() => handleExpand('faq2')}
            title={t('lifeAtSmarttie.FAQS.FAQ2.title')}
            content={t('lifeAtSmarttie.FAQS.FAQ2.description')}
          />
        </AnimationWrapper>

        <AnimationWrapper from='bottom'>
          <FAQItem
            isOpen={expanded === 'faq3'}
            controlledChange={() => handleExpand('faq3')}
            title={t('lifeAtSmarttie.FAQS.FAQ3.title')}
            content={t('lifeAtSmarttie.FAQS.FAQ3.description')}
          />
        </AnimationWrapper>

        <AnimationWrapper from='bottom'>
          <FAQItem
            isOpen={expanded === 'faq4'}
            controlledChange={() => handleExpand('faq4')}
            title={t('lifeAtSmarttie.FAQS.FAQ4.title')}
            content={t('lifeAtSmarttie.FAQS.FAQ4.description')}
          />
        </AnimationWrapper>

        <AnimationWrapper from='bottom'>
          <FAQItem
            isOpen={expanded === 'faq5'}
            controlledChange={() => handleExpand('faq5')}
            title={t('lifeAtSmarttie.FAQS.FAQ5.title')}
            content={t('lifeAtSmarttie.FAQS.FAQ5.description')}
          />
        </AnimationWrapper>

        <AnimationWrapper from='bottom'>
          <FAQItem
            isOpen={expanded === 'faq6'}
            controlledChange={() => handleExpand('faq6')}
            title={t('lifeAtSmarttie.FAQS.FAQ6.title')}
            content={t('lifeAtSmarttie.FAQS.FAQ6.description')}
          />
        </AnimationWrapper>
      </div>
    </section>
  );
};

export default FAQ;
