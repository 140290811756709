import React from 'react';
import img1 from '../../../assets/image/community-img-1.jpg';
import img2 from '../../../assets/image/community-img-2.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import LazyLoad from 'react-lazyload';

const Community = () => {
  const { t } = useTranslation();

  return (
    <section className='grid grid-cols-12 pt-10 md:pb-20'>
      <div className='col-span-12 pt-0 md:pt-6'>
        <h3 className='pt-0 md:pt-3 text-2xl md:text-3xl font-bold w-full text-center md:text-left'>
          <AnimationWrapper from='left'>
            {t('lifeAtSmarttie.community.point1.title')}
            <span className='text-primary'>
              {t('lifeAtSmarttie.community.point1.subtitle')}
            </span>
          </AnimationWrapper>
        </h3>
      </div>
      <div className='col-span-12 flex flex-col-reverse md:flex-row gap-y-8 sm:gap-y-4 sm:gap-x-8 pt-4 pb-10 md:pb-6'>
        <div className='w-full md:w-[47%] md:self-stretch grid place-items-center'>
          <LazyLoad once height={150}>
            <AnimationWrapper from='left'>
              <img
                src={img1}
                alt='img1'
                className='min-w-full min-h-[200px] object-cover rounded-xl'
              />
            </AnimationWrapper>
          </LazyLoad>
        </div>
        <div className='w-full md:w-[53%] text-center md:text-left px-0 sm:px-5 md:px-0'>
          <AnimationWrapper from='right'>
            <p className='flex'>
              <span className='text-xl leading-4 md:leading-3 text-primary font-extrabold pr-2'>
                &#8226;
              </span>
              {t('lifeAtSmarttie.community.point1.description1')}
            </p>
            <p className='flex'>
              <span className='text-xl leading-4 md:leading-3 text-primary font-extrabold pr-2'>
                &#8226;
              </span>
              {t('lifeAtSmarttie.community.point1.description2')}
            </p>
          </AnimationWrapper>
        </div>
      </div>

      <div className='col-span-12 pt-0 md:pt-6'>
        <h3 className='pt-0 md:pt-3 text-2xl md:text-3xl font-bold w-full text-center md:text-left'>
          <AnimationWrapper from='left'>
            <span className='text-primary'>
              {t('lifeAtSmarttie.community.point2.title')}
            </span>
            {t('lifeAtSmarttie.community.point2.subtitle')}
          </AnimationWrapper>
        </h3>
      </div>
      <div className='col-span-12 flex flex-col-reverse md:flex-row gap-y-8 sm:gap-y-4 sm:gap-x-8 pt-4 pb-10 md:pb-6'>
        <div className='w-full md:w-[47%] md:self-stretch grid place-items-center'>
          <LazyLoad once height={150}>
            <AnimationWrapper from='left'>
              <img
                src={img2}
                alt='img1'
                className='min-w-full min-h-[200px] object-cover rounded-xl'
              />
            </AnimationWrapper>
          </LazyLoad>
        </div>
        <div className='w-full md:w-[53%] text-center md:text-left px-0 sm:px-5 md:px-0'>
          <AnimationWrapper from='right'>
            <p className='flex'>
              <span className='text-xl leading-4 md:leading-3 text-primary font-extrabold pr-2'>
                &#8226;
              </span>
              {t('lifeAtSmarttie.community.point2.description1')}
            </p>
            <p className='flex'>
              <span className='text-xl leading-4 md:leading-3 text-primary font-extrabold pr-2'>
                &#8226;
              </span>
              {t('lifeAtSmarttie.community.point2.description2')}
            </p>
          </AnimationWrapper>
        </div>
      </div>
    </section>
  );
};

export default Community;
