import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import calendar from '../../../assets/image/calendar.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';

const BlogsCard = () => {
  const [_blogs, setBlogs] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function _getData() {
      try {
        const data = await getData();

        setBlogs(data.blogs);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    _getData();
  }, []);

  if (!_blogs.length) return null;

  return (
    <AnimationWrapper from='right'>
      <div className='border mt-8 border-white/20 p-4 rounded-xl text-white'>
        <h3 className='text-lg xl:text-xl text-center xl:text-left font-bold'>
          {t('blogs.title')}{' '}
          <span className='text-primary'>{t('blogs.title2')}</span>
        </h3>
        <div className='flex flex-col gap-3'>
          {_blogs.map((blog) => (
            <a
              href={blog.link}
              key={blog.id}
              className='flex items-center gap-3 hover:bg-primary/70 p-2 rounded-lg cursor-pointer relative overflow-hidden'
            >
              <div className='flex items-center gap-3'>
                <img
                  src={
                    blog._embedded &&
                    blog._embedded['wp:featuredmedia'] &&
                    blog._embedded['wp:featuredmedia'].length > 0
                      ? blog._embedded['wp:featuredmedia'][0].source_url
                      : ''
                  }
                  className='w-[70px] h-[70px] object-cover'
                  alt="Blog's cover"
                />

                <div className=''>
                  <div className='flex items-center'>
                    <img
                      src={calendar}
                      alt='calendar'
                      className='mr-1 w-max h-max'
                    />
                    <p className='text-xs text-white'>
                      {format(new Date(blog.date), 'MMMM dd, yyyy')}
                    </p>
                  </div>
                  <p className='text-sm py-1'>{blog.title.rendered}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default BlogsCard;

async function getData(page = 1, per_page = 3) {
  const res = await fetch(
    `https://blog.smarttie.ca/wp-json/wp/v2/posts?_embed&page=${page}&per_page=${per_page}`
  );

  return {
    blogs: await res.json(),
    total: res.headers.get('X-WP-Total'),
    totalPages: res.headers.get('X-WP-TotalPages'),
  };
}
