import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import bgImageJpg from '../../../assets/image/jobs-card-bg.jpg';

const JobsCard = () => {
  const { t } = useTranslation();

  return (
    <AnimationWrapper from='right' time={0.3} delay={0}>
      <div className='cursor-default relative rounded-md my-6 md:my-0 min-h-[40vh] md:min-h-0 grid place-items-center xl:block'>
        <div className='flex flex-col items-center justify-center xl:items-start xl:justify-between px-5 xl:px-8 py-4 xl:py-8 gap-7 relative z-[1]'>
          <div className='flex flex-col gap-3 xl:block'>
            <h3 className='text-3xl md:text-2xl font-bold text-center xl:text-left'>
              <span className='text-primary'>
                {t('lifeAtSmarttie.jobsCard.title1')}
              </span>{' '}
              {t('lifeAtSmarttie.jobsCard.title2')}
            </h3>
            <p className='text-lg md:text-sm text-center xl:text-left'>
              {t('lifeAtSmarttie.jobsCard.subtitle1')} <br />{' '}
              {t('lifeAtSmarttie.jobsCard.subtitle2')}
            </p>
          </div>
          <a
            href='https://www.linkedin.com/company/smarttie/jobs/'
            className='bg-white/90 text-black font-bold rounded-full px-7 py-2 inline-block w-[60%] text-center xl:inline relative overflow-hidden hover-animation'
          >
            <div className='w-full h-full grid place-items-center absolute top-0 left-0'>
              <div className='bg-animation-center rounded-full'></div>
            </div>
            <span className='relative z-[2]'>
              {t('lifeAtSmarttie.jobsCard.button')}
            </span>
          </a>
        </div>
        <div className='absolute w-full h-full top-0 left-0 z-0'>
          <picture>
            <img
              src={bgImageJpg}
              alt='hero-bg'
              className='w-full h-full object-cover object-center rounded-md'
            />
          </picture>
        </div>
      </div>
    </AnimationWrapper>
  );
};

export default JobsCard;
