import React from 'react';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import LazyLoad from 'react-lazyload';

const InfoImage = ({ title, description, img, reverse }) => {
  return (
    <div
      className={`col-span-12 flex flex-col md:flex-row gap-y-8 sm:gap-y-4 sm:gap-x-8 pt-4 pb-10 md:pb-6 ${reverse &&
        'md:flex-row-reverse'}`}
    >
      <div className='w-full md:w-[47%] text-center md:text-left md:self-stretch grid place-items-center px-0 sm:px-5 md:px-0'>
        <AnimationWrapper from={reverse ? 'right' : 'left'}>
          <p>
            {title && <span className='text-primary font-bold'>{title}</span>}
            {description}
          </p>
        </AnimationWrapper>
      </div>
      <div className='w-full md:w-[53%] md:self-stretch grid place-items-center'>
        <LazyLoad once height={150}>
          <AnimationWrapper from={reverse ? 'left' : 'right'}>
            <img
              src={img}
              alt='img1'
              className='min-w-full min-h-[200px] md:min-h-0 object-cover rounded-xl'
            />
          </AnimationWrapper>
        </LazyLoad>
      </div>
    </div>
  );
};

export default InfoImage;
