import { Link } from 'gatsby';
import React from 'react';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import bgHeroWebp from '../../../assets/image/life-hero-bg.webp';
import bgHeroJpg from '../../../assets/image/life-hero-bg.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className='relative'>
      <div className='relative h-[40vh] max-h-[300px] z-[1] container mx-auto grid'>
        <div className='flex justify-center self-stretch flex-col gap-2'>
          <AnimationWrapper from='left' time={0.3} delay={0}>
            <h1 className='text-3xl sm:text-4xl font-bold'>
              {t('lifeAtSmarttie.header.title')}{' '}
              <span className='text-primary'>Smarttie</span>
            </h1>
            <p className='text-sm sm:text-base max-w-[500px]'>
              {t('lifeAtSmarttie.header.description')}
            </p>
          </AnimationWrapper>
        </div>
        <div className='w-auto absolute left-8 md:left-20 top-4 cursor-default'>
          <AnimationWrapper from='left' time={0.3} delay={0}>
            <div className='bg-white/25 backdrop-blur-lg flex items-center w-auto px-4 py-1 rounded-full text-white text-sm gap-2 cursor-default'>
              <Link to='/' className='hover:text-primary font-bold'>
                {t('lifeAtSmarttie.header.navegation.title1')}
              </Link>
              <span> &gt;&gt; </span>
              <Link to='#' className='hover:text-primary font-bold'>
                {t('lifeAtSmarttie.header.navegation.title2')}
              </Link>
            </div>
          </AnimationWrapper>
        </div>
      </div>
      <div className='absolute w-full h-full top-0 left-0 z-0'>
        <picture>
          <source
            srcSet={bgHeroWebp}
            type='image/webp'
            className='w-full h-full object-cover object-center'
          />
          <img
            src={bgHeroJpg}
            alt='hero-bg'
            className='w-full h-full object-cover object-center'
          />
        </picture>
      </div>
    </div>
  );
};

export default Header;
