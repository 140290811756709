import React from 'react';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import LazyLoad from 'react-lazyload';

const Benefit = ({ children, bg, delay }) => {
  return (
    <div className='col-span-12 md:col-span-6  w-full self-stretch rounded-xl cursor-default relative overflow-hidden benefit benefit-hover-animation'>
      <LazyLoad once height={200} style={{ height: '100%' }}>
        <AnimationWrapper from='bottom' delay={delay} stretch={true}>
          <div
            className={`w-full h-full py-10 xl:py-16 px-6 xl:px-12 bg-cover bg-center ${bg &&
              bg}`}
          >
            <div className='w-full h-full grid place-items-center absolute top-0 left-0'>
              <div className='benefit-bg-animation-center rounded-full'></div>
            </div>
            {children}
          </div>
        </AnimationWrapper>
      </LazyLoad>
    </div>
  );
};

export default Benefit;
