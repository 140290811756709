import React from 'react';

const BenefitList = ({ children }) => {
  return (
    <ul className='text-base md:text-sm xl:text-base list-disc w-[80%] mx-auto pt-3'>
      {children}
    </ul>
  );
};

export default BenefitList;
