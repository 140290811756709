import React, { useState, useEffect, useRef } from 'react';
import Header from './sections/Header';
import AboutUs from './sections/AboutUs';
import JobsCard from './components/JobsCard';
import Benefits from './sections/Benefits';
import OurValues from './sections/OurValues';
import SmarttieLife from './sections/SmarttieLife';
import Community from './sections/Community';
import JobsCTA from './sections/JobsCTA';
import FAQ from './sections/FAQ';
import BlogsCard from './components/BlogsCard';
import LazyLoad from 'react-lazyload';

const LeftSection = React.memo(function LeftSection() {
  return (
    <section className='col-span-12 lg:col-span-8 pt-12'>
      <AboutUs />
      <div className='lg:hidden'>
        <JobsCard />
      </div>

      <Benefits />
      <OurValues />
      <SmarttieLife />
      <Community />
      <FAQ />
    </section>
  );
});

const CTASection = React.memo(function CTASection() {
  return (
    <LazyLoad once height={250}>
      <JobsCTA />
    </LazyLoad>
  );
});

const LifeAtSmarttie = () => {
  const ref = useRef(null);
  const [refWidth, setRefWidth] = useState(0);

  const handleResize = () => {
    if (ref.current) {
      setRefWidth(ref.current.offsetWidth);
    }
  };

  // Listen the ref element width change
  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div ref={ref} className='text-white overflow-hidden'>
      <Header />
      <div className='grid grid-cols-12 gap-x-4 xl:gap-x-0 container mx-auto'>
        <LeftSection />
        {refWidth >= 1024 && (
          <aside className='hidden lg:block lg:col-span-4 lg:col-start-auto xl:col-span-3 xl:col-start-10 pt-24'>
            <JobsCard />
            <BlogsCard />
          </aside>
        )}
      </div>
      <CTASection />
    </div>
  );
};

export default LifeAtSmarttie;
